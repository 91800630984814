.blog {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 32px 0; 
}
.blog .content {
  min-height: 200px;
  background-color: rgb(223, 223, 223);
  min-width: 800px;
  max-width: 800px;
  margin: 8px 0;
  border-radius: 8px;
}
.blog .content h1,
.blog .content h2,
.blog .content h3,
.blog .content h4,
.blog .content h5
{
  text-transform: none;
  color: #4d4d4d;
}
.blog .content a, 
.blog .content a:link, 
.blog .content a:visited, 
.blog .content a:active
{
  color: black
}

.blog .content .code{
  font-family: Consolas, monospace;
  font-size: 12px;
  padding: 12px;
  margin: 12px 0;
  background-color: #4d4d4d;
  color: white;
  overflow-x: auto;
}
.blog .top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px;
  border-bottom: 1px solid gray;
}
.blog .top h3 {
  line-height: 0.7em;
}
.blog .top span {
  margin-left: 12px;
  font-size: 10px;
}

.blog .body {
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.blog .body img {
  width: 100%;
  height: auto;
}
.controller {
  margin-top: 22px;
  font-size: 32px;
  text-transform: uppercase;
}
@media only screen and (max-width: 888px) {
  .blog .content {
    min-width: 100%;
  }
}